export const data = [
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463", // building Id
    address: "116  Morningside", //address
    intensity: "511", // unit number, const ignored
    distraction: "1609459390", //time
    forgetfulness: "8", // ?, const ignored
    col6: [30, 20, 50], // sum of to hot, open, flame
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },

  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
  {
    buildingId: "2463",
    address: "100 Mornelle Court",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [20, 30, 50],
  },
  {
    buildingId: "2463",
    address: "116  Morningside",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [30, 20, 50],
  },
  {
    buildingId: "2463",
    address: "300 Alton Towers Circle",
    intensity: "511",
    distraction: "1609459390",
    forgetfulness: "8",
    col6: [50, 20, 30],
  },
];

// export const data = [
//   {
//     buildingId: "2463",
//     address: "100 Mornelle Court",
//     intensity: "511",
//     distraction: "1609459390",
//     forgetfulness: "8",
//     col6: [20, 30, 50],
//   },
//   {
//     buildingId: "2463",
//     address: "116  Morningside",
//     intensity: "511",
//     distraction: "1609459390",
//     forgetfulness: "8",
//     col6: [30, 20, 50],
//   },
//   {
//     buildingId: "2463",
//     address: "300 Alton Towers Circle",
//     intensity: "511",
//     distraction: "1609459390",
//     forgetfulness: "8",
//     col6: [50, 20, 30],
//   },
// ];
