import { useTable, useSortBy, usePagination } from "react-table";
import React, { useState } from "react";
import SingleStackedBar from "../../components/SingleStackedHorizontalBarGraph";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import "./tableStyle.scss";

export default function BuildingInsightsTable(props) {
  const [clickedCategory, setClickedCategory] = useState(null);

  const graphData = ({ value, columnProps }) => {
    return (
      <div className="block">
        <SingleStackedBar data={value} />
      </div>
    );
  };

  const handleRowClick = (row) => {
    props.loadUnitsData(row.buildingId);
  };

  const sortAddress = (rowA, rowB) => {
    return rowA.original.address.localeCompare(
      rowB.original.address,
      undefined,
      {
        numeric: true,
      }
    );
  };

  const sortGraph = (rowA, rowB) => {
    const sumA =
      rowA.original.intensity +
      rowA.original.distraction +
      rowA.original.forgetfulness;
    const sumB =
      rowB.original.intensity +
      rowB.original.distraction +
      rowB.original.forgetfulness;
    return sumA - sumB;
  };

  const calculateScore = (row) => {
    return Math.round((
      100 -
      (row.forgetfulness / 75) * ((row.intensity / 2 + row.distraction / 2) / 2)
    ));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Address",
        accessor: "address",
        sortType: sortAddress,
      },
      {
        Header: "Safety Score",
        accessor: (row) => calculateScore(row),
        Cell: ({ value }) => {
          let scoreColor = "#4CAF50";
          if (value >= 80) scoreColor = "#4CAF50";
          else if (value >= 60) scoreColor = "#8BC34A";
          else if (value >= 40) scoreColor = "#FFC107";
          else if (value >= 20) scoreColor = "#FF9800";
          else scoreColor = "#F44336";

          return (
            <div style={{ color: scoreColor, textAlign: "center" }}>
              {value.toFixed(0)}
            </div>
          );
        },
        sortType: (rowA, rowB) => {
          const scoreA = calculateScore(rowA.original);
          const scoreB = calculateScore(rowB.original);
          return scoreA - scoreB;
        },
        style: {
          textAlign: "center",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: { sortBy: [{ id: "address", desc: false }], pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const processedData = [
    { name: "Very Low", score: 0, buildings: [] },
    { name: "Low", score: 0, buildings: [] },
    { name: "Medium", score: 0, buildings: [] },
    { name: "High", score: 0, buildings: [] },
    { name: "Very High", score: 0, buildings: [] },
  ];

  props.data.forEach((row) => {
    const score = calculateScore(row);
    if (score <= 20) {
      processedData[0].score += 1;
      processedData[0].buildings.push(row);
    } else if (score <= 39) {
      processedData[1].score += 1;
      processedData[1].buildings.push(row);
    } else if (score <= 59) {
      processedData[2].score += 1;
      processedData[2].buildings.push(row);
    } else if (score <= 79) {
      processedData[3].score += 1;
      processedData[3].buildings.push(row);
    } else {
      processedData[4].score += 1;
      processedData[4].buildings.push(row);
    }
  });

  const handleBarClick = (category) => {
    setClickedCategory(clickedCategory === category ? null : category);
  };

  const getCategoryUnits = () => {
    if (!clickedCategory) return [];
    const categoryData = processedData.find(
      (category) => category.name === clickedCategory
    );
    return categoryData ? categoryData.buildings : [];
  };

  return (
    <div className="container m-auto mt-6 mr-100">
      <h1 className="text-4xl">My Properties</h1>
      <div className="table-graph-container" style={{ display: "flex" }}>
        <table
          className="building-table drop-shadow-lg"
          {...getTableProps()}
          style={{ border: "solid 1px black" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      borderBottom: "solid 3px black",
                      background: "white",
                      color: "black",
                      fontWeight: "bold",
                      padding: "10px",
                      textAlign: "center", 
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => handleRowClick(row.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                        background: i % 2 === 0 ? "#EEEEEE" : "white",
                        textAlign: "center", 
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="col-container">
          <div className="graph-summary-container" style={{ display:"flex"}}>
            <div className="graph-container" style={{ margin: "20px 20px 0 0" }}>
            <BarChart
              width={500}
              height={300}
              data={processedData}
              margin={{ top: 5, right: 30, left: 40, bottom: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" label={{ value: "Safety Score", position: "insideBottom", offset: -10 }} />
              <YAxis>
                <Label
                  angle={-90}
                  position="insideLeft"
                  style={{ textAnchor: "middle" }}
                >
                  Number of Buildings
                </Label>
              </YAxis>
              <Tooltip />
              {/* <Legend /> */}
              <Bar
                dataKey="score"
                fill="#8884d8"
                onClick={(data) => handleBarClick(data.name)}
              />
            </BarChart>
            </div>
            <div
              className="clicked-category-container"
              style={{
                marginLeft: "20px",
                maxHeight: "300px",
                overflowY: "auto",
                border: "1px solid #ddd",
                padding: "10px",
                width: "200px",
              }}
            >
              <h2>{clickedCategory ? "" : "Select a Bar to List Buildings"}</h2>
              <ul>
                {getCategoryUnits().map((building) => (
                  <li key={building.buildingId}>{building.address}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="info-textbox">
            <h2>{"Insights Summary"}</h2>
            <ul>
              <li>
              {" "} Currently, <strong>6,133</strong> out of the <strong>53,091</strong>{" "}
              units in your portfolio are offline.
              </li>
              <li>
              The average safety score across all properties is at{" "} <span style={{ color: "#8BC34A", fontWeight: "bold" }}>83%</span>.
              </li>
              <li>
              <span style={{ color: "#F44336", fontWeight: "bold" }}>14</span> of your buildings are considered high risk.
              </li>
            </ul>
            <p>
            Monitoring the activity levels, intensity, and potential
            distractions to further improve the safety score is recommended. {" "} <span style={{  fontWeight: "bold" }}>Select a building to learn more.</span>
              {/* Monitoring the activity levels, intensity, and potential
              distractions to further improve the safety score is recommended. 
              For detailed insights into specific cooking types and encountered
              alarms, click on the unit data to access detailed analysis on the
              cooking activity of each unit. */}
            </p>
          </div>
        </div>
      </div>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

