import { useTable, useSortBy, usePagination } from "react-table";
import React, { useState } from "react";
import SingleStackedBar from "../../components/SingleStackedHorizontalBarGraph";
import "./tableStyle.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";

export default function UnitInsightsTable(props) {
  const [clickedCategory, setClickedCategory] = useState(null);

  const graphData = ({ value, columnProps }) => {
    return (
      <div className="block">
        <SingleStackedBar data={value} />
      </div>
    );
  };

  const sortGraph = (rowA, rowB) => {
    if (
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness >
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness
    )
      return 1;
    if (
      rowB.original.intensity +
        rowB.original.distraction +
        rowB.original.forgetfulness >
      rowA.original.intensity +
        rowA.original.distraction +
        rowA.original.forgetfulness
    )
      return -1;
    return 0;
  };

  const handleRowClick = (row) => {
    props.loadAnalytics();
  };

  const mapRangeToString = (value) => {
    if (value < 20) return "Very Low";
    else if (value >= 20 && value < 40) return "Low";
    else if (value >= 40 && value < 60) return "Medium";
    else if (value >= 60 && value < 80) return "High";
    else if (value >= 80) return "Very High";
    else return "Unknown";
  };

  const calculateScore = (row) => {
    const rawScore =
      100 -
      (row.forgetfulness / 75) *
        ((row.intensity / 2 + row.distraction / 2) / 2);
    return Math.round(rawScore);
  };

  const getTextColor = (value) => {
    if (value === "Very High") {
      return "#F44336";
    } else if (value === "High") {
      return "#FF9800";
    } else if (value === "Medium") {
      return "#FFC107";
    } else if (value === "Low") {
      return "#8BC34A";
    } else {
      return "#4CAF50";
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Room",
        accessor: "UnitNumber",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <div
            style={{
              color: value === "Online" ? "green" : "red",
              fontWeight: "bold",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Activity",
        accessor: "forgetfulness",
        Cell: ({ value }) => (
          <div
            style={{
              color: getTextColor(mapRangeToString(value)),
              fontWeight: "bold",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {mapRangeToString(value)}
          </div>
        ),
      },
      {
        Header: "Intensity",
        accessor: "intensity",
        Cell: ({ value }) => (
          <div
            style={{
              color: getTextColor(mapRangeToString(value)),
              fontWeight: "bold",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {mapRangeToString(value)}
          </div>
        ),
      },
      {
        Header: "Distraction",
        accessor: "distraction",
        Cell: ({ value }) => (
          <div
            style={{
              color: getTextColor(mapRangeToString(value)),
              fontWeight: "bold",
              padding: "10px",
              textAlign: "center",
            }}
          >
            {mapRangeToString(value)}
          </div>
        ),
      },
      {
        Header: "Safety Score",
        accessor: (row) => calculateScore(row),
        Cell: ({ value }) => {
          let scoreColor = "#4CAF50";
          if (value >= 80) {
            scoreColor = "#4CAF50";
          } else if (value >= 60) {
            scoreColor = "#8BC34A";
          } else if (value >= 40) {
            scoreColor = "#FFC107";
          } else if (value >= 20) {
            scoreColor = "#FF9800";
          } else {
            scoreColor = "#F44336";
          }

          return (
            <div style={{ color: scoreColor, textAlign: "center"  }}> 
              {value.toFixed(0)}
            </div>
          );
        },
        sortType: (rowA, rowB) => {
          const scoreA = calculateScore(rowA.original);
          const scoreB = calculateScore(rowB.original);
          return scoreA - scoreB;
        },
        style: {
          textAlign: "center",
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: props.data,
      initialState: { sortBy: [{ id: "UnitNumber" }], pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  const processedData = [
    { name: "0-20", score: 0, units: [] },
    { name: "21-39", score: 0, units: [] },
    { name: "40-59", score: 0, units: [] },
    { name: "60-79", score: 0, units: [] },
    { name: "80-100", score: 0, units: [] },
  ];

  props.data.forEach((row) => {
    const score = calculateScore(row);
    if (score <= 20) {
      processedData[0].score += 1;
      processedData[0].units.push(row);
    } else if (score <= 39) {
      processedData[1].score += 1;
      processedData[1].units.push(row);
    } else if (score <= 59) {
      processedData[2].score += 1;
      processedData[2].units.push(row);
    } else if (score <= 79) {
      processedData[3].score += 1;
      processedData[3].units.push(row);
    } else {
      processedData[4].score += 1;
      processedData[4].units.push(row);
    }
  });

  const handleBarClick = (category) => {
    setClickedCategory(clickedCategory === category ? null : category);
  };

  return (
    <div className="container m-auto mt-6">
      <h1 className="text-2xl">{props.data[0].address}</h1>
      <div className="table-graph-container" style={{ display: "flex" }}>
        <table
          className="unit-table drop-shadow-lg"
          {...getTableProps()}
          style={{ border: "solid 1px black" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={
                        column.style
                          ? column.style
                          : {
                              borderBottom: "solid 3px black",
                              background: "white",
                              color: "black",
                              fontWeight: "bold",
                              padding: "10px",
                              textAlign: "center", 
                            }
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => handleRowClick(row.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: i % 2 === 0 ? "#EEEEEE" : "white",
                          textAlign: "center", 
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div
          className="graph-container"
          style={{
            marginLeft: "20px",
            display: "flex",
            flexWrap: "wrap",
            height: "90%",
          }}
        >
          <BarChart
            width={500}
            height={300}
            data={processedData}
            margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="name" label={{ value: "Safety Score", position: "insideBottom", offset: -15 }} />
            <YAxis>
              <Label
                angle={-90}
                position="insideLeft"
                style={{ textAnchor: "middle" }}
              >
                Number of Buildings
              </Label>
            </YAxis>
            <Tooltip />
            {/* <Legend /> */}
            <Bar
              dataKey="score"
              fill="#8884d8"
              onClick={(data) => handleBarClick(data.name)}
            />
          </BarChart>
          <div
            className="clicked-category-details"
            style={{
              marginLeft: "20px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            <h2>{clickedCategory ? "" : "Select a Bar to List Rooms"}</h2>
            <ul>
              {clickedCategory &&
                processedData
                  .find((category) => category.name === clickedCategory)
                  .units.map((unit) => (
                    <li key={unit.UnitNumber}>
                      {unit.UnitNumber} - Safety Score:{" "}
                      {calculateScore(unit)}%
                    </li>
                  ))}
            </ul>
          </div>
          <div className="info-textbox">
            <h2>{"Insights Summary"}</h2>
            <p>
              Currently, <strong>12</strong> out of the <strong>130</strong>{" "}
              units in the building are{" "} 
              <span style={{ color: "#F44336", fontWeight: "bold" }}>offline</span>. 
              The average safety score across
              all units is at{" "}
              <span style={{ color: "#8BC34A", fontWeight: "bold" }}>75%</span>.
            </p>
            <ul>
              <li>
                High Cooking Activity observed in <strong>50</strong> units
              </li>
              <li>
                Intense Cooking Sessions observed in <strong>15</strong> units
              </li>
              <li>
                Significant Distractions observed in <strong>22</strong> units
              </li>
            </ul>
            <p>
            Monitoring the activity levels, intensity, and potential
            distractions to further improve the safety score is recommended. 
            {" "} <span style={{  fontWeight: "bold" }}>Select a unit to learn more.</span>
            </p>
          </div>
        </div>
      </div>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
