export const data = [
  {
    email: "csmith@bigreit.com",
    firstName: "Charles",
    lastName: "Smith",
    dateCreated: "2022-03-02",
    dateModified: "2022-03-05",
  },
  {
    email: "thiggs@bigreit.com",
    firstName: "Terry",
    lastName: "Higgs",
    dateCreated: "2022-04-01",
    dateModified: "",
  },
  {
    email: "jstowe@bigreit.com",
    firstName: "James",
    lastName: "Stowe",
    dateCreated: "2022-04-02",
    dateModified: "",
  },
];
